/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx } from "theme-ui"
import showdown from "showdown"

const MarkdownContent = ({ content, style }) => {
  // Generate Content
  const converter = new showdown.Converter({ simpleLineBreaks: true })
  let html = converter.makeHtml(content)

  // Hack to remove random backslash with single line breaks. See: https://github.com/netlify/netlify-cms/issues/512
  //html = html.toString().replace(/\\<br\s?\/>/g, "<br/>")

  return <span style={style} dangerouslySetInnerHTML={{ __html: html }} />
}

export default MarkdownContent