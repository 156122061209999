/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { graphql } from "gatsby"
import { BaseStyles, jsx } from "theme-ui"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import Metadata from "../components/Metadata"

// components
import Hero from "../components/Hero"
import Title from "../components/page-blocks/Title"

// utilities
import { formatPhoneNumber } from "../utilities/formatPhoneNumber.tsx"

// styles
const mainContainer = {
  backgroundColor: "white",
  justifyContent: "center",
  display: "flex",
  width: "100%",
}
const columnContainer = {
  marginTop: "20px",
  marginBottom: "50px",
  width: "800px",
  display: "flex",
  flexWrap: "wrap",
  marginLeft: "10px",
  marginRight: "10px",
}
const officeContainer = {
  width: ["100%", "100%", "50%"],
  paddingBottom: "40px",
  fontWeight: 300,
}

const ContactUsTemplate = ({ data }) => {
  const d = data.markdownRemark.frontmatter
  return (
    <Layout>
      <Metadata title={d.title} />
      <BaseStyles>
        {d.hero && d.hero.isHero && (
          <Hero
            headline={d.hero.heroHeadline}
            description={d.hero.heroDescription}
          />
        )}
        <Title title="Offices" />
        <div sx={mainContainer}>
          <div sx={columnContainer}>
            {d.offices &&
              d.offices.map((office, i) => {
                return (
                  <div sx={officeContainer} key={i}>
                    <div sx="contactPageColumn">
                      <div sx={{ fontSize: "32px" }}>{office.location}</div>
                      <div sx={{ fontSize: "17px" }}>
                        {formatPhoneNumber(office.phoneNumber)}
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </BaseStyles>
    </Layout>
  )
}

export default ContactUsTemplate

ContactUsTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        hero {
          isHero
          heroHeadline
          heroDescription
        }
        offices {
          location
          phoneNumber
        }
      }
    }
  }
`
